import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  LineChartOutlined,
  FileSearchOutlined,
  UserOutlined,
  DollarCircleOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {Layout, Menu} from "antd";
import React, {useEffect, useState} from "react";
import "./App.css";
import {Outlet, Link, useNavigate} from "react-router-dom";
import {store} from "./redux/store";
import {setUser} from "./redux/features/userSlice";

const {Header, Sider, Content} = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub9"];
const items = [
  getItem("User", "sub1", <UserOutlined/>, [
    getItem("", "1", <Link to="user-info">Thông tin</Link>),
    getItem("", "2", <Link to="user-mail">Hộp thư</Link>),
    getItem("", "3", <Link to="user-moneylog">Money Log</Link>),
    getItem("", "4", <Link to="user-loginlog">Đăng nhập Log</Link>),
    getItem("", "5", <Link to="user-broadcastlog">Broadcast Log</Link>),
    getItem("", "51", <Link to="user-withdraw-sun">Rút từ Sun</Link>),
    getItem("", "52", <Link to="user-withdraw-hit">Rút từ Hit</Link>),
    getItem("", "53", <Link to="user-codepay">Nạp CODEPAY</Link>),
  ]),
  getItem("Log", "sub2", <FileSearchOutlined/>, [
    getItem("", "6", <Link to="log-gamelog">Game Log</Link>),
    getItem("", "46", <Link to="log-gamebetlog">Game BET Log</Link>),
    getItem("", "7", <Link to="log-fishlog">Fish Log</Link>),
    getItem("", "8", <Link to="log-fishlogdetail">Fish Log Detail</Link>),
    getItem("", "9", <Link to="log-slotlog">Slot Log</Link>),
    getItem("", "10", <Link to="log-slotlogdetail">Slot Log Detail</Link>),
    getItem("", "11", <Link to="log-jackpotlog">Jackpot Log</Link>),
    getItem("", "12", <Link to="log-jackpotfishlog">Jackpot Fish Log</Link>),
    getItem("", "13", <Link to="log-trackinglog">Tracking Log</Link>),
  ]),
  getItem("Cấu hình", "sub3", <SettingOutlined/>, [
    getItem("", "14", <Link to="config-updategame">Update Game</Link>),
    getItem("", "15", <Link to="config-payment">Nạp/Đổi</Link>),
    getItem("", "16", <Link to="config-game">Game</Link>),
    getItem("", "17", <Link to="config-jackpot">Jackpot</Link>),
    getItem("", "18", <Link to="config-jackpotlive">Jackpot Live</Link>),
    getItem("", "19", <Link to="config-gamestock">Stock Cá</Link>),
    getItem("", "50", <Link to="config-bank">Quỹ bank</Link>),
  ]),
  getItem("Nạp/Đổi", "sub4", <DollarCircleOutlined/>, [
    getItem("Thẻ cào", "sub5", null, [
      getItem("", "20", <Link to="payment-card-nap">Nạp thẻ</Link>),
      getItem("", "21", <Link to="payment-card-duyet">Duyệt thẻ</Link>),
      getItem("", "22", <Link to="payment-card-kho">Kho thẻ</Link>),
      getItem("", "23", <Link to="payment-card-thongkekho">Thống kê thẻ</Link>),
    ]),
    getItem("Code", "sub6", null, [
      getItem("", "25", <Link to="payment-code-list">Danh sách</Link>),
      getItem("", "26", <Link to="payment-code-nap">Nạp code</Link>),
      getItem("", "27", <Link to="payment-code-doi">Đổi code</Link>),
    ]),
    getItem("Ngân hàng", "sub7", null, [
      getItem("", "28", <Link to="payment-bank-nap">Nạp ngân hàng</Link>),
      getItem("", "29", <Link to="payment-bank-doi">Đổi ngân hàng</Link>),
      getItem("", "30", <Link to="payment-bank-duyet">Duyệt ngân hàng</Link>),
    ]),
    getItem("Momo", "sub8", null, [
      getItem("", "31", <Link to="payment-momo-nap">Nạp Momo</Link>),
      getItem("", "32", <Link to="payment-momo-doi">Đổi Momo</Link>),
      getItem("", "33", <Link to="payment-momo-duyet">Duyệt Momo</Link>),
    ]),
  ]),
  getItem("Thống kê", "sub9", <LineChartOutlined/>, [
    getItem("", "41", <Link to="thongke-gold">Tiền</Link>),
    getItem("", "42", <Link to="thongke-payment">Nạp/Đổi</Link>),
    getItem("", "43", <Link to="thongke-dau">DAU</Link>),
    getItem("", "44", <Link to="thongke-tracking">Tracking</Link>),
    getItem("", "45", <Link to="thongke-general">Tổng quan</Link>),
  ]),
];

const itemsUser = [
  getItem("User", "sub1", <UserOutlined/>, [
    getItem("", "1", <Link to="user-info">Thông tin</Link>),
    getItem("", "2", <Link to="user-mail">Hộp thư</Link>),
    getItem("", "3", <Link to="user-moneylog">Money Log</Link>),
    getItem("", "4", <Link to="user-loginlog">Đăng nhập Log</Link>),
    getItem("", "5", <Link to="user-broadcastlog">Broadcast Log</Link>),
  ]),
  getItem("Log", "sub2", <FileSearchOutlined/>, [
    getItem("", "6", <Link to="log-gamelog">Game Log</Link>),
    getItem("", "7", <Link to="log-fishlog">Fish Log</Link>),
    getItem("", "8", <Link to="log-fishlogdetail">Fish Log Detail</Link>),
    getItem("", "9", <Link to="log-slotlog">Slot Log</Link>),
    getItem("", "10", <Link to="log-slotlogdetail">Slot Log Detail</Link>),
    getItem("", "11", <Link to="log-jackpotlog">Jackpot Log</Link>),
    getItem("", "12", <Link to="log-jackpotfishlog">Jackpot Fish Log</Link>),
    getItem("", "13", <Link to="log-trackinglog">Tracking Log</Link>),
  ]),
  getItem("Config", "sub3", <SettingOutlined/>, [
    getItem("", "14", <Link to="config-updategame">Update Game</Link>),
    getItem("", "17", <Link to="config-jackpot">Config Jackpot</Link>),
    getItem("", "18", <Link to="config-jackpotlive">Jackpot Live</Link>),
    getItem("", "19", <Link to="config-gamestock">Stock Ca</Link>),
  ]),
  getItem("Payment", "sub4", <DollarCircleOutlined/>, [
    getItem("Card", "sub5", null, [
      getItem("", "20", <Link to="payment-card-nap">Nạp thẻ</Link>),
      getItem("", "21", <Link to="payment-card-duyet">Duyệt thẻ</Link>),
      getItem("", "22", <Link to="payment-card-kho">Kho thẻ</Link>),
      getItem("", "23", <Link to="payment-card-thongkekho">Thống kê thẻ</Link>),
    ]),
    getItem("Code", "sub6", null, [
      getItem("", "25", <Link to="payment-code-list">Danh sách</Link>),
      getItem("", "26", <Link to="payment-code-nap">Nạp code</Link>),
      getItem("", "27", <Link to="payment-code-doi">Đổi code</Link>),
    ]),
    getItem("Bank", "sub7", null, [
      getItem("", "28", <Link to="payment-bank-nap">Nạp Bank</Link>),
      getItem("", "29", <Link to="payment-bank-doi">Đổi Bank</Link>),
      getItem("", "30", <Link to="payment-bank-duyet">Duyệt Bank</Link>),
    ]),
    getItem("Momo", "sub8", null, [
      getItem("", "31", <Link to="payment-momo-nap">Nạp Momo</Link>),
      getItem("", "32", <Link to="payment-momo-doi">Đổi Momo</Link>),
      getItem("", "33", <Link to="payment-momo-duyet">Duyệt Momo</Link>),
    ]),
  ]),
  getItem("Thống kê", "sub9", <LineChartOutlined/>, [
    getItem("", "41", <Link to="thongke-gold">Tiền</Link>),
    getItem("", "42", <Link to="thongke-payment">Nạp/Đổi</Link>),
    getItem("", "43", <Link to="thongke-dau">DAU</Link>),
    getItem("", "44", <Link to="thongke-tracking">Tracking</Link>),
  ]),
];

const itemsCSKH = [
  /*getItem("User", "sub1", <UserOutlined/>, [
    getItem("", "1", <Link to="user-info">Thông tin</Link>),
    getItem("", "2", <Link to="user-mail">Hộp thư</Link>),
    getItem("", "3", <Link to="user-moneylog">Money Log</Link>),
    getItem("", "4", <Link to="user-loginlog">Đăng nhập Log</Link>),
    getItem("", "5", <Link to="user-broadcastlog">Broadcast Log</Link>),
    getItem("", "51", <Link to="user-withdraw-sun">Rút từ Sun</Link>),
    getItem("", "52", <Link to="user-withdraw-hit">Rút từ Hit</Link>),
    getItem("", "53", <Link to="user-codepay">Nạp CODEPAY</Link>),
  ]),
  getItem("Log", "sub2", <FileSearchOutlined/>, [
    getItem("", "6", <Link to="log-gamelog">Game Log</Link>),
    getItem("", "46", <Link to="log-gamebetlog">Game BET Log</Link>),
    getItem("", "7", <Link to="log-fishlog">Fish Log</Link>),
    getItem("", "8", <Link to="log-fishlogdetail">Fish Log Detail</Link>),
    getItem("", "9", <Link to="log-slotlog">Slot Log</Link>),
    getItem("", "10", <Link to="log-slotlogdetail">Slot Log Detail</Link>),
    getItem("", "11", <Link to="log-jackpotlog">Jackpot Log</Link>),
    getItem("", "12", <Link to="log-jackpotfishlog">Jackpot Fish Log</Link>),
    getItem("", "13", <Link to="log-trackinglog">Tracking Log</Link>),
  ]),*/
  getItem("Nạp/Đổi", "sub4", <DollarCircleOutlined/>, [
    getItem("Thẻ cào", "sub5", null, [
      getItem("", "20", <Link to="payment-card-nap">Nạp thẻ</Link>),
      getItem("", "21", <Link to="payment-card-duyet">Duyệt thẻ</Link>)
    ]),
    getItem("Code", "sub6", null, [
      getItem("", "25", <Link to="payment-code-list">Danh sách</Link>),
      getItem("", "26", <Link to="payment-code-nap">Nạp code</Link>)
    ]),
    getItem("Ngân hàng", "sub7", null, [
      getItem("", "28", <Link to="payment-bank-nap">Nạp Ngân hàng</Link>),
      getItem("", "29", <Link to="payment-bank-doi">Đổi Ngân hàng</Link>),
      getItem("", "30", <Link to="payment-bank-duyet">Duyệt Ngân hàng</Link>),
    ]),
    getItem("Momo", "sub8", null, [
      getItem("", "31", <Link to="payment-momo-nap">Nạp Momo</Link>),
      getItem("", "32", <Link to="payment-momo-doi">Đổi Momo</Link>),
      getItem("", "33", <Link to="payment-momo-duyet">Duyệt Momo</Link>),
    ]),
  ]),
  getItem("Thống kê", "sub9", <LineChartOutlined/>, [
    getItem("", "41", <Link to="thongke-gold">Tiền</Link>),
    getItem("", "42", <Link to="thongke-payment">Nạp/Đổi</Link>),
    getItem("", "43", <Link to="thongke-dau">DAU</Link>),
    getItem("", "44", <Link to="thongke-tracking">Tracking</Link>),
  ]),
];

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [wMeny, setWMenu] = useState(200);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const user = useSelector((state) => state.user.userState);
  let navigate = useNavigate();

  const logout = () => {
    const isConfirm = window.confirm("Đăng xuất khỏi hệ thống?");
    if (!isConfirm) {
      return;
    }
    store.dispatch(setUser({}));
    navigate("/");
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (user && user['isLogout']) {
      logout();
    }
  }, [user]);

  const getItems = () => {
    if (user.role.toLowerCase() === process.env.REACT_APP_USERNAME_ADMIN.toLowerCase())
      return items;
    if (user.role.toLowerCase() === process.env.REACT_APP_USERNAME_USER.toLowerCase())
      return itemsUser;
    else if (user.role.toLowerCase() === process.env.REACT_APP_USERNAME_CSKH.toLowerCase())
      return itemsCSKH;
  }

  return (
    <Layout
      hasSider
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        theme="dark"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="w-[35px] h-[25px] bg-logo bg-no-repeat bg-cover m-auto my-[18px]"/>
        <Menu
          theme="dark"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          style={{height: "100vh", borderRight: 0}}
          defaultSelectedKeys={["1"]}
          items={getItems()}
        />
      </Sider>
      <Layout className="site-layout" style={{marginLeft: wMeny}}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            margin: "6px  16px 0px 16px",
            borderRadius: 6,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => {
                setCollapsed(!collapsed);
                setTimeout(() => {
                  setWMenu(collapsed ? 200 : 80);
                }, 80);
              },
            }
          )}

          <LogoutOutlined title={"Đăng Xuất"} style={{float: "right"}} className={'trigger'}
                          onClick={logout}></LogoutOutlined>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            borderRadius: 6,
            minHeight: 280,
          }}
        >
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
